import SvgIconWrapper from "./SvgIconWrapper";

export default function QuestionOutlinedIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7330)">
          <path
            d="M20 2C10.0578 2 2 10.0578 2 20C2 29.9422 10.0578 38 20 38C29.9422 38 38 29.9422 38 20C38 10.0578 29.9422 2 20 2ZM20 34.625C11.9352 34.625 5.375 28.0641 5.375 20C5.375 11.9359 11.9352 5.375 20 5.375C28.0648 5.375 34.625 11.9359 34.625 20C34.625 28.0641 28.0648 34.625 20 34.625ZM20 25.625C18.7344 25.625 17.75 26.6094 17.75 27.875C17.75 29.1406 18.6711 30.125 20 30.125C21.2023 30.125 22.25 29.1406 22.25 27.875C22.25 26.6094 21.2023 25.625 20 25.625ZM22.3273 11H18.7344C15.9922 11 13.8125 13.1797 13.8125 15.9219C13.8125 16.8359 14.5859 17.6094 15.5 17.6094C16.4141 17.6094 17.1875 16.8359 17.1875 15.9219C17.1875 15.0781 17.8273 14.375 18.6711 14.375H22.2641C23.1711 14.375 23.9375 15.0781 23.9375 15.9219C23.9375 16.4844 23.6562 16.9133 23.1641 17.1945L19.1562 19.6484C18.5938 20 18.3125 20.5625 18.3125 21.125V22.25C18.3125 23.1641 19.0859 23.9375 20 23.9375C20.9141 23.9375 21.6875 23.1641 21.6875 22.25V22.1094L24.8586 20.1406C26.3352 19.2266 27.2492 17.6094 27.2492 15.9219C27.3125 13.1797 25.1328 11 22.3273 11Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7330">
            <rect
              width="36"
              height="36"
              fill="white"
              transform="translate(2 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function ToggleStateOffIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1467_12425)">
          <path
            d="M28.3333 11.6667H11.6667C7.06666 11.6667 3.33333 15.4 3.33333 20C3.33333 24.6 7.06666 28.3334 11.6667 28.3334H28.3333C32.9333 28.3334 36.6667 24.6 36.6667 20C36.6667 15.4 32.9333 11.6667 28.3333 11.6667ZM11.6667 25C8.89999 25 6.66666 22.7667 6.66666 20C6.66666 17.2334 8.89999 15 11.6667 15C14.4333 15 16.6667 17.2334 16.6667 20C16.6667 22.7667 14.4333 25 11.6667 25Z"
            fill="#BDBDBD"
          />
        </g>
        <defs>
          <clipPath id="clip0_1467_12425">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function FourChevronRightIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_914_7406)">
          <path
            d="M15.5 33.5C14.9243 33.5 14.3483 33.2803 13.9095 32.8408C13.0306 31.9619 13.0306 30.5381 13.9095 29.6592L23.5719 20L13.9095 10.3391C13.0306 9.46015 13.0306 8.03633 13.9095 7.15742C14.7884 6.27851 16.2123 6.27851 17.0912 7.15742L28.3412 18.4074C29.2201 19.2863 29.2201 20.7102 28.3412 21.5891L17.0912 32.8391C16.6517 33.282 16.0759 33.5 15.5 33.5Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_914_7406">
            <rect
              width="18"
              height="36"
              fill="white"
              transform="translate(11 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function ArrowRotateOneArrowIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.0078 20.6247C38.0078 30.2009 30.2087 38 20.6325 38C16.8408 38 13.2454 36.7909 10.2182 34.5187C9.12084 33.6969 8.8989 32.1394 9.72219 31.0413C10.5456 29.9479 12.1038 29.7244 13.1988 30.5466C15.3593 32.1693 17.9326 33.0253 20.6401 33.0253C27.477 33.0257 33.0412 27.4615 33.0412 20.6247C33.0412 13.7879 27.4794 8.22375 20.6402 8.22375C16.7368 8.22531 13.0662 10.094 10.7381 13.1748H15.6581C17.0294 13.1748 18.1414 14.2853 18.1414 15.6581C18.1414 17.0309 17.0317 18.1414 15.6581 18.1414H4.48329C3.11205 18.1414 2 17.0309 2 15.6581V4.48329C2 3.1105 3.11205 2 4.48329 2C5.85454 2 6.96659 3.1105 6.96659 4.48329V9.93335C10.2337 5.76995 15.2003 3.25018 20.6325 3.25018C30.2087 3.25018 38.0078 11.0485 38.0078 20.6247Z"
          fill="currentColor"
        />
      </svg>
    </SvgIconWrapper>
  );
}

import Styled from "./index.styles";

export type HeaderColumnProps = {
  align?: "left" | "center" | "right";
  className?: string;
  isSticky?: boolean;
  width?: number;

  left?: number;
} & React.HTMLProps<HTMLTableCellElement>;

const TableHeaderColumn = ({
  align = "left",
  children,
  className,
  isSticky,
  width,
  left,
}: React.PropsWithChildren<HeaderColumnProps>) => {
  return (
    <Styled.tableHeaderColumn
      className={className}
      isSticky={isSticky}
      width={width}
      left={left}
    >
      <Styled.innerContainer align={align}>{children}</Styled.innerContainer>
    </Styled.tableHeaderColumn>
  );
};

export default TableHeaderColumn;

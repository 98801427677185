import SvgIconWrapper from "./SvgIconWrapper";

export default function XMarkCircleIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_914_7496)">
          <path
            d="M2 20C2 10.0578 10.0578 2 20 2C29.9422 2 38 10.0578 38 20C38 29.9422 29.9422 38 20 38C10.0578 38 2 29.9422 2 20ZM14.3047 16.632L17.6164 19.9367L14.3047 23.3047C13.6508 23.9656 13.6508 25.0344 14.3047 25.632C14.9656 26.3492 16.0344 26.3492 16.632 25.632L19.9367 22.3836L23.3047 25.632C23.9656 26.3492 25.0344 26.3492 25.632 25.632C26.3492 25.0344 26.3492 23.9656 25.632 23.3047L22.3836 19.9367L25.632 16.632C26.3492 16.0344 26.3492 14.9656 25.632 14.3047C25.0344 13.6508 23.9656 13.6508 23.3047 14.3047L19.9367 17.6164L16.632 14.3047C16.0344 13.6508 14.9656 13.6508 14.3047 14.3047C13.6508 14.9656 13.6508 16.0344 14.3047 16.632Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_914_7496">
            <rect
              width="36"
              height="36"
              fill="white"
              transform="translate(2 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

import { COLOR } from "../../styles/colors";

import SvgIconWrapper from "./SvgIconWrapper";

export default function ToggleStateOnIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={COLOR.primary_600}
      >
        <g clipPath="url(#clip0_1467_12424)">
          <path
            d="M28.3333 11.6667H11.6667C7.06666 11.6667 3.33333 15.4 3.33333 20C3.33333 24.6 7.06666 28.3334 11.6667 28.3334H28.3333C32.9333 28.3334 36.6667 24.6 36.6667 20C36.6667 15.4 32.9333 11.6667 28.3333 11.6667ZM28.3333 25C25.5667 25 23.3333 22.7667 23.3333 20C23.3333 17.2334 25.5667 15 28.3333 15C31.1 15 33.3333 17.2334 33.3333 20C33.3333 22.7667 31.1 25 28.3333 25Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1467_12424">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function InfoOutlinedIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 2C10.0578 2 2 10.0578 2 20C2 29.9422 10.0578 38 20 38C29.9422 38 38 29.9422 38 20C38 10.0578 29.9422 2 20 2ZM20 34.625C11.9352 34.625 5.375 28.0641 5.375 20C5.375 11.9359 11.9352 5.375 20 5.375C28.0648 5.375 34.625 11.9359 34.625 20C34.625 28.0641 28.0648 34.625 20 34.625Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.75 13.25C22.75 12.0076 21.7424 11 20.5 11C19.2555 11 18.25 12.0055 18.25 13.25C18.25 14.4945 19.2576 15.5 20.5 15.5C21.7424 15.5 22.75 14.4924 22.75 13.25ZM16 27.3125C16 28.2477 16.7594 29 17.6875 29H23.3125C24.2477 29 25 28.2441 25 27.3125C25 26.3809 24.2441 25.625 23.3125 25.625H22.1875V19.4375C22.1875 18.5059 21.4316 17.75 20.5 17.75H18.25C17.3219 17.75 16.5625 18.5059 16.5625 19.4375C16.5625 20.3691 17.3184 21.125 18.25 21.125H18.8125V25.625H17.6875C16.7559 25.625 16 26.3773 16 27.3125Z"
          fill="currentColor"
        />
      </svg>
    </SvgIconWrapper>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function RadioActiveCheckedIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_621_2655)">
          <path
            d="M0 20C0 8.95312 8.95312 0 20 0C31.0469 0 40 8.95312 40 20C40 31.0469 31.0469 40 20 40C8.95312 40 0 31.0469 0 20ZM20 27.5C24.1406 27.5 27.5 24.1406 27.5 20C27.5 15.7891 24.1406 12.5 20 12.5C15.7891 12.5 12.5 15.7891 12.5 20C12.5 24.1406 15.7891 27.5 20 27.5Z"
            fill="#5482D6"
          />
        </g>
        <defs>
          <clipPath id="clip0_621_2655">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

import Styled from "./index.styles";

interface Props<T> {
  children: React.ReactNode;
  className?: string;
}

const TableHeaderGroup = <T,>({ children, className }: Props<T>) => {
  return (
    <Styled.tableHeaderGroup className={className}>
      {children}
    </Styled.tableHeaderGroup>
  );
};

export default TableHeaderGroup;

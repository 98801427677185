import { useEffect, useRef } from "react";

import Portal from "@sellernote/_shared/src/components/Portal";
import useToolTip, {
  ToolTipPropsV2,
} from "@sellernote/_shared/src/headlessComponents/useToolTip";
import {
  useCheckIsMobile,
  useWindowResize,
} from "@sellernote/_shared/src/utils/common/hook";
import XMarkDefaultIcon from "@sellernote/_sds-v2/src/components/svgIcons/XMarkDefaultIcon";

import { COLOR } from "../../styles/colors";

import Styled from "./index.styles";

/**
 * 툴팁으로 표시되는 내용
 * - 툴팁 위치계산에 본문 사이즈가 사용되므로, 툴팁이 표시되지 않은 상황에서도 dom에는 추가되어있어야한다
 */
export default function ToolTipBody({
  isVisibleToolTipBody,
  setIsVisibleToolTipBody,
  triggerTargetInfo,
  checkTriggerTarget,
  title,
  desc,
  width,
  position,
  tooltipBodyStyle,
}: Pick<
  ReturnType<typeof useToolTip>,
  | "isVisibleToolTipBody"
  | "setIsVisibleToolTipBody"
  | "triggerTargetInfo"
  | "checkTriggerTarget"
> &
  Pick<
    ToolTipPropsV2,
    "title" | "desc" | "width" | "position" | "tooltipBodyStyle"
  >) {
  const [windowWidth] = useWindowResize();

  const { isMobile } = useCheckIsMobile();

  /**
   * window 스크롤시 툴팁위치를 다시 계산
   * - 특히 모바일에서는 X버튼을 통해서만 닫히므로 스크롤시 위치를 재계산 해줘야함
   * - TODO: SideSheet 내부에서는 작동이 안하는 문제가 있음. (SG급한 작업 마친후 수정 예정)
   */
  useEffect(() => {
    window.addEventListener("scroll", checkTriggerTarget);

    return () => {
      window.removeEventListener("scroll", checkTriggerTarget);
    };
  }, [checkTriggerTarget]);

  /**
   * 툴팁 body의 width, height를 이용하여 정확한 center를 찾을 때 사용함
   */
  const tooltipBodyRef = useRef<HTMLDivElement>(null);
  const tooltipBodySize = (() => ({
    height: tooltipBodyRef.current?.clientHeight,
    width: tooltipBodyRef.current?.clientWidth,
  }))();

  return (
    <Portal selector="#app-portal">
      <Styled.tooltipBody
        tooltipBodyStyle={tooltipBodyStyle}
        isVisibleToolTipBody={isVisibleToolTipBody}
        position={position}
        triggerTargetInfo={triggerTargetInfo}
        tooltipBodySize={tooltipBodySize}
        width={width}
        vw={windowWidth}
        isMobile={isMobile}
        ref={tooltipBodyRef}
        className="tooltip-body"
      >
        <div className="contents">
          {title && (
            <div className="title-container">
              {title.Icon && (
                <title.Icon
                  width={16}
                  height={16}
                  color={COLOR.wh}
                  className="icon"
                />
              )}

              <div className="title">{title.message}</div>
            </div>
          )}

          {desc && (
            <Styled.desc>
              {Array.isArray(desc) ? (
                <div className="desc-list">
                  {desc.map((descItem, i) => (
                    <div className="item" key={i}>
                      <div className="bullet">・</div>

                      <div className="contents">{descItem}</div>
                    </div>
                  ))}
                </div>
              ) : (
                desc
              )}
            </Styled.desc>
          )}
        </div>

        {/**
         * <isVisibleToolTipBody를 다시 체크하는 이유>
         * parent에서 isVisibleToolTipBody에따라 visibility 속성만 조정하는데(툴팁의 height 계산 때문에 dom이 항상 있어야함)
         * SVG관련 충돌이 나므로 아예 Node자체를 없애도록 하기 위함
         */}
        {isMobile && isVisibleToolTipBody && (
          <div
            className="close"
            onClick={(e) => {
              e.stopPropagation();
              setIsVisibleToolTipBody(false);
            }}
            style={{ color: COLOR.wh }}
          >
            <XMarkDefaultIcon width={16} height={16} color={COLOR.wh} />
          </div>
        )}
      </Styled.tooltipBody>
    </Portal>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function RadioActiveIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_621_2648)">
          <path
            d="M40 20C40 31.0469 31.0469 40 20 40C8.95312 40 0 31.0469 0 20C0 8.95312 8.95312 0 20 0C31.0469 0 40 8.95312 40 20ZM20 3.75C11.0234 3.75 3.75 11.0234 3.75 20C3.75 28.9766 11.0234 36.25 20 36.25C28.9766 36.25 36.25 28.9766 36.25 20C36.25 11.0234 28.9766 3.75 20 3.75Z"
            fill="#BDBDBD"
          />
        </g>
        <defs>
          <clipPath id="clip0_621_2648">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

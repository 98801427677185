import SvgIconWrapper from "./SvgIconWrapper";

export default function RadioDisabledCheckedIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_921_10867)">
          <path
            d="M19.9834 3.74463C11.0068 3.74463 3.7334 11.0181 3.7334 19.9946C3.7334 28.9712 11.0068 36.2446 19.9834 36.2446C28.96 36.2446 36.2334 28.9712 36.2334 19.9946C36.2334 11.0181 28.96 3.74463 19.9834 3.74463Z"
            fill="#E0E0E0"
          />
          <path
            d="M40 20C40 31.0469 31.0469 40 20 40C8.95313 40 0 31.0469 0 20C0 8.95313 8.95313 0 20 0C31.0469 0 40 8.95313 40 20ZM20 3.75C11.0234 3.75 3.75 11.0234 3.75 20C3.75 28.9766 11.0234 36.25 20 36.25C28.9766 36.25 36.25 28.9766 36.25 20C36.25 11.0234 28.9766 3.75 20 3.75Z"
            fill="#E0E0E0"
          />
          <path
            d="M19.9775 12.5C15.7666 12.5 12.4775 15.7891 12.4775 20C12.4775 24.1406 15.7666 27.5 19.9775 27.5C24.1182 27.5 27.4775 24.1406 27.4775 20C27.4775 15.7891 24.1182 12.5 19.9775 12.5Z"
            fill="#F5F5F5"
          />
        </g>
        <defs>
          <clipPath id="clip0_921_10867">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

import Styled from "./index.styles";

type Props = {
  hasGroupBorder?: boolean;
  className?: string;
} & React.HTMLProps<HTMLTableRowElement>;

const TableRow = ({
  hasGroupBorder,
  children,
  onClick,
  className,
}: React.PropsWithChildren<Props>) => {
  return (
    <Styled.tableRow
      hasGroupBorder={hasGroupBorder}
      onClick={onClick}
      className={className}
    >
      {children}
    </Styled.tableRow>
  );
};

export default TableRow;

import styled, { css } from "styled-components";

import { InputSelectUiType } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { showVerticalScrollBarAlways } from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div<{ width?: string }>`
  width: ${({ width }) => (width ? width : "100%")};

  > .label {
    ${setFontStyle("Body8")};
    color: ${COLOR.grayScale_800};
    margin-bottom: 0.125rem;
  }

  em {
    ${setFontStyle("Caption")};
    color: ${COLOR.pointWarning};
  }

  > .option-list-wrapper {
    position: relative;
  }

  > .note-wrapper {
    position: relative;
  }

  &.disabled {
    > .label {
      color: ${COLOR.grayScale_400};
    }
  }

  > .warning-message-wrapper {
    position: relative;

    .warning-message {
      position: absolute;
      top: 2px;
      left: 0;
      ${setFontStyle("Caption")};
      color: ${COLOR.pointWarning};
    }
  }
`;

interface SelectedProps {
  uiType: InputSelectUiType;
}
const selected = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${(props: SelectedProps) => {
    switch (props.uiType) {
      case "filled": {
        return css`
          background-color: ${COLOR.grayScale_100};
          border-bottom: 1px solid ${COLOR.grayScale_500};
          padding: 10px 8px;

          &.disabled {
            cursor: not-allowed;

            > .value {
              &.has-value {
                color: ${COLOR.grayScale_400};
              }
            }
          }

          &.opened {
            border-bottom: 1px solid ${COLOR.secondaryDeepBlue};
          }

          > .value {
            ${setFontStyle("Body7")};
            color: ${COLOR.grayScale_400};
            height: auto;
            max-height: 22px;
            overflow: hidden;

            &.has-value {
              color: ${COLOR.grayScale_800};
            }
          }
        `;
      }
      case "outline": {
        return css`
          background-color: transparent;
          border: 1px solid ${COLOR.grayScale_300};
          padding: 10px 8px;
          border-radius: 2px;

          &.disabled {
            cursor: not-allowed;

            > .value {
              &.has-value {
                color: ${COLOR.grayScale_400};
              }
            }
          }

          > .value {
            ${setFontStyle("Body7")};
            color: ${COLOR.grayScale_300};
            height: auto;
            max-height: 22px;
            overflow: hidden;

            &.has-value {
              color: ${COLOR.grayScale_800};
            }
          }
        `;
      }
    }
  }}
`;

const optionList = styled.div`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  max-height: 14.375rem;
  overflow: auto;
  background-color: ${COLOR.white};
  ${showVerticalScrollBarAlways()}

  > .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0.5rem;
    background-color: ${COLOR.white};
    color: ${COLOR.grayScale_800};
    cursor: pointer;
    height: auto;
    min-height: 2.75rem;

    &.disabled {
      background-color: ${COLOR.grayScale_100};
      color: ${COLOR.grayScale_300};
      cursor: not-allowed;
    }

    &:not(.disabled):hover {
      background-color: ${COLOR.primaryBlue_10};
    }

    > .label {
      ${setFontStyle("Body7")};
    }

    > .desc {
      ${setFontStyle("Body8")};
    }
  }
`;

const note = styled.div`
  ${setFontStyle("Caption")};
  color: ${COLOR.pointWarning};
  position: absolute;
  top: 0.125rem;
  left: 0;
`;

export default {
  container,
  selected,
  optionList,
  note,
};

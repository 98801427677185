import Styled from "./index.styles";

interface Props<T> {
  children: React.ReactNode;
  className?: string;
}

const TableHeader = <T,>({ children, className }: Props<T>) => {
  return (
    <Styled.tableHeader className={className}>{children}</Styled.tableHeader>
  );
};

export default TableHeader;

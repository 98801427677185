import { css } from "styled-components";

export type ElevationLevel =
  | "level1"
  | "level2"
  | "level3"
  | "level4"
  | "level5"
  | "radix_sm"
  | "radix_lg";

export const setElevationStyle = (
  level: ElevationLevel,
  shadowType: "text-shadow" | "box-shadow" | undefined = "box-shadow"
) => {
  switch (level) {
    case "level1":
      return css`
        ${shadowType}: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      `;
    case "level2":
      return css`
        ${shadowType}: 0px 2px 6px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.30);
      `;
    case "level3":
      return css`
        ${shadowType}: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
      `;
    case "level4":
      return css`
        ${shadowType}: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3);
      `;
    case "level5":
      return css`
        ${shadowType}: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3);
      `;
    case "radix_sm":
      return css`
        ${shadowType}: 0px 2px 10px rgba(0, 0, 0, 0.07);
      `;
    case "radix_lg":
      return css`
        ${shadowType}: 0px 10px 38px -10px rgba(13, 18, 22, 0.35),
          0px 10px 20px -15px rgba(14, 18, 22, 0.2);
      `;
  }
};

export const setDimmedLayer = () => css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.6);
`;

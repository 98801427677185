import SvgIconWrapper from "./SvgIconWrapper";

export default function RadioInvalidIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 19C14.971 19 19 14.971 19 10C19 5.02897 14.971 1 10 1C5.02897 1 1 5.02897 1 10C1 14.971 5.02897 19 10 19ZM4.5 10C4.5 6.96166 6.96166 4.5 10 4.5C13.0383 4.5 15.5 6.96166 15.5 10C15.5 13.0383 13.0383 15.5 10 15.5C6.96166 15.5 4.5 13.0383 4.5 10Z"
          fill="#FF002E"
          stroke="#FFEAEF"
          strokeWidth="2"
        />
        <path
          d="M10 3.5C6.40937 3.5 3.5 6.40937 3.5 10C3.5 13.5906 6.40937 16.5 10 16.5C13.5906 16.5 16.5 13.5906 16.5 10C16.5 6.40937 13.5906 3.5 10 3.5Z"
          fill="white"
        />
      </svg>
    </SvgIconWrapper>
  );
}

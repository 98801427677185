import Styled from "./index.styles";

export type BodyCellProps = {
  align?: "left" | "center" | "right";
  className?: string;
  isSticky?: boolean;
  width?: number;
  left?: number;
} & React.HTMLProps<HTMLTableCellElement>;

const TableBodyCell = ({
  align = "left",
  rowSpan,
  children,
  className,
  isSticky,
  width,
  left,
}: React.PropsWithChildren<BodyCellProps>) => {
  return (
    <Styled.tableBodyCell
      align={align}
      rowSpan={rowSpan}
      className={className}
      isSticky={isSticky}
      width={width}
      left={left}
    >
      {children}
    </Styled.tableBodyCell>
  );
};

export default TableBodyCell;

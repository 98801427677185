import SvgIconWrapper from "./SvgIconWrapper";

export default function CheckboxInvalidIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="16" transform="translate(2 2)" fill="white" />
        <rect
          x="1"
          y="1"
          width="18"
          height="18"
          rx="3"
          stroke="#FFEAEF"
          strokeWidth="2"
        />
        <rect
          x="2.5"
          y="2.5"
          width="15"
          height="15"
          rx="1.5"
          stroke="#FF002E"
        />
      </svg>
    </SvgIconWrapper>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function XMarkDefaultIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_914_7646)">
          <path
            d="M31.7656 28.2344C32.7422 29.2109 32.7422 30.793 31.7656 31.7695C31.2812 32.2578 30.6406 32.5 30 32.5C29.3594 32.5 28.7203 32.2559 28.2328 31.7676L20 23.5391L11.768 31.7656C11.2797 32.2578 10.6398 32.5 10 32.5C9.36016 32.5 8.72109 32.2578 8.23242 31.7656C7.25586 30.7891 7.25586 29.207 8.23242 28.2305L16.4668 19.9961L8.23242 11.7656C7.25586 10.7891 7.25586 9.20703 8.23242 8.23047C9.20898 7.25391 10.791 7.25391 11.7676 8.23047L20 16.4687L28.2344 8.23438C29.2109 7.25781 30.793 7.25781 31.7695 8.23438C32.7461 9.21094 32.7461 10.793 31.7695 11.7695L23.5352 20.0039L31.7656 28.2344Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_914_7646">
            <rect
              width="25"
              height="40"
              fill="white"
              transform="translate(7.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

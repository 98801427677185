import React from "react";

import TableBody from "./body/Body";
import TableBodyCell from "./body/BodyCell";
import TableRow from "./body/Row";
import TableHeaderGroup from "./header/Group";
import TableHeader from "./header/Header";
import TableHeaderColumn from "./header/HeaderColumn";
import Styled from "./index.styles";

interface Props {
  children: React.ReactNode;
  width?: string;
  className?: string;
}

const TableMain = ({ children, className, width }: Props) => {
  return (
    <Styled.table
      width={width}
      className={`${className ? className : ""} table`}
    >
      {children}
    </Styled.table>
  );
};

export const Table = Object.assign(TableMain, {
  Header: TableHeader,
  HeaderGroup: TableHeaderGroup,
  HeaderColumn: TableHeaderColumn,

  Body: TableBody,
  Row: TableRow,
  BodyCell: TableBodyCell,
});

import styled, { css } from "styled-components";

import { ButtonPropsV2 } from "@sellernote/_shared/src/headlessComponents/button/useButton";

import { COLOR } from "../../../styles/colors";
import { setFontFace } from "../../../styles/typography";

type ButtonStyleProps = Pick<ButtonPropsV2, "borderType" | "size" | "iconInfo">;

const container = styled.div<Pick<ButtonPropsV2, "width" | "size">>`
  display: ${({ size }) => (size === "block" ? "block" : "inline-block")};

  ${({ width }) =>
    width
      ? css`
          width: ${width};

          button {
            width: 100%;
          }
        `
      : ""};
`;

const iconButtonPadding = {
  small: { left: "9px 16px 9px 12px", right: "9px 12px 9px 16px" },
  normal: { left: "12px 24px 12px 16px", right: "12px 16px 12px 24px" },
  large: { left: "20px 24px 20px 16px", right: "20px 16px 20px 24px" },
  block: { left: "20px 24px 20px 20px", right: "20px 20px 20px 24px" },
  inputFillContainer: {
    left: "15px 24px 15px 16px",
    right: "15px 16px 15px 24px",
  },
};

const commonButtonStyle = css<ButtonStyleProps>`
  border-radius: 2px;
  cursor: pointer;

  // icon을 추가했을때 정렬을 맞추기 위해 사용
  display: flex;
  align-items: center;
  justify-content: center;

  ${setFontFace("Default_KR")};
  letter-spacing: -0.2px;
  font-weight: 400;
  line-height: 1;
  min-width: max-content;

  ${({ size }) => {
    switch (size) {
      case "small":
        return css`
          font-size: 14px;
          padding: 9px 16px;
        `;

      case "normal":
        return css`
          font-size: 16px;
          padding: 12px 24px;
          height: 40px;
        `;

      case "large":
        return css`
          font-size: 16px;
          padding: 20px 24px;
        `;

      case "block":
        return css`
          font-size: 16px;
          padding: 20px 24px;
          width: 100%;
          border-radius: 0;
        `;

      case "inputFillContainer":
        return css`
          font-size: 16px;
          padding: 16px 24px;
          width: 100%;
          border-radius: 0;
        `;
    }
  }};
`;

const button = styled.button<ButtonStyleProps>`
  ${commonButtonStyle};

  .icon {
    ${({ iconInfo }) => {
      if (iconInfo?.position === "left") {
        return css`
          margin-right: 8px;
        `;
      }

      if (iconInfo?.position === "right") {
        return css`
          margin-left: 8px;
        `;
      }
    }};
  }

  ${({ iconInfo, size }) => css`
    padding: ${iconInfo ? iconButtonPadding[size][iconInfo.position] : ""};
  `};

  ${({ iconInfo }) => {
    if (iconInfo?.position === "left" || iconInfo?.position === "right")
      return css`
        display: flex;
        justify-content: center;
      `;
  }};
`;

const linkButton = styled.a<ButtonStyleProps & { disabled?: boolean }>`
  ${commonButtonStyle};

  ${({ disabled, borderType }) => {
    if (!disabled) return css``;

    return css`
      color: ${COLOR.grayScale_400};
      cursor: not-allowed;

      ${borderType === "filled"
        ? css`
            background-color: ${COLOR.grayScale_300};
          `
        : css`
            background-color: ${COLOR.grayScale_200};
            border: 1px solid ${COLOR.grayScale_300};
          `};
    `;
  }}

  .icon {
    ${({ iconInfo }) => {
      if (iconInfo?.position === "left") {
        return css`
          margin-right: 8px;
        `;
      }

      if (iconInfo?.position === "right") {
        return css`
          margin-left: 8px;
        `;
      }
    }};
  }

  ${({ iconInfo, size }) => css`
    padding: ${iconInfo ? iconButtonPadding[size][iconInfo.position] : ""};
  `};

  ${({ iconInfo }) => {
    if (iconInfo?.position === "left" || iconInfo?.position === "right")
      return css`
        display: flex;
        justify-content: center;
      `;
  }};
`;

export default {
  container,
  button,
  linkButton,
};

import SvgIconWrapper from "./SvgIconWrapper";

export default function CheckboxFilledActiveCheckedIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_621_2652)">
          <path
            d="M34.2857 0C37.4375 0 40 2.55804 40 5.71429V34.2857C40 37.4375 37.4375 40 34.2857 40H5.71429C2.55804 40 0 37.4375 0 34.2857V5.71429C0 2.55804 2.55804 0 5.71429 0H34.2857ZM30.3393 16.0536C31.3125 15.0804 31.3125 13.4911 30.3393 12.5179C29.3661 11.5446 27.7768 11.5446 26.8036 12.5179L17.1429 22.1786L13.1964 18.2321C12.2232 17.2589 10.6339 17.2589 9.66071 18.2321C8.68482 19.2054 8.68482 20.7946 9.66071 21.7679L15.375 27.4821C16.3482 28.4554 17.9375 28.4554 18.9107 27.4821L30.3393 16.0536Z"
            fill="#5482D6"
          />
        </g>
        <defs>
          <clipPath id="clip0_621_2652">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function PlaneIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.1438 16C34.2812 16 38 17.8125 38 20C38 22.25 34.2812 24 32.1438 24H24.8563L18.575 34.9937C18.2188 35.6187 17.5563 36 16.8375 36H13.325C12.6625 36 12.1813 35.3625 12.3625 34.725L15.425 24H9L6.3 27.6C6.1125 27.85 5.8125 28 5.5 28H2.875C2.3875 28 2 27.6063 2 27.125C2 27.0438 2.0125 26.9625 2.03125 26.8813L4 20L2.03125 13.1188C2.00625 13.0375 2 12.9563 2 12.875C2 12.3875 2.39375 12 2.875 12H5.5C5.8125 12 6.1125 12.15 6.3 12.4L9 16H15.4313L12.3688 5.275C12.1813 4.6375 12.6625 4 13.325 4H16.8375C17.5563 4 18.2188 4.3875 18.575 5.00625L24.8563 16H32.1438Z"
          fill="currentColor"
        />
      </svg>
    </SvgIconWrapper>
  );
}

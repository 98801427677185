import { CSSProperties, useCallback } from "react";

import { SvgIconComponent } from "@sellernote/_shared/src/headlessComponents/useSvgIcon";
import RadioActiveCheckedIcon from "@sellernote/_sds-v2/src/components/svgIcons/RadioActiveCheckedIcon";
import RadioActiveIcon from "@sellernote/_sds-v2/src/components/svgIcons/RadioActiveIcon";
import RadioDisabledIcon from "@sellernote/_sds-v2/src/components/svgIcons/RadioDisabledIcon";

import Styled from "./index.styles";

export type TabDirection = "horizontal" | "vertical";

export type TabUiType =
  | "box"
  | "lined"
  | "rounded"
  | "text"
  | "radioCheck"
  | "boxDark"
  | "file";

// 일반적으로 "center" 정렬이고, "right" 정렬은 "box" 의 "vertical" 만 개발완료.
export type TabAlign = "center" | "right";

type TabUiTypeBox = {
  uiType: "box";
  direction: TabDirection;
  hasBorder?: boolean;
  isStretch?: boolean;
  align?: TabAlign;
};

type TabUiTypeLined<T> = {
  uiType: "lined";
  direction: "horizontal";
  /**
   * Figma상에 붉은 색 점으로 표시됨 (탭 내부에 새소식/이벤트가 있을때 등에 사용)
   */
  emphasizedTabs?: T[];
};

type TabUiTypeRounded = {
  uiType: "rounded";
  direction: "horizontal";
};

type TabUiTypeText = {
  uiType: "text";
  direction: "horizontal";
};

type TabUiTypeRadioCheck = {
  uiType: "radioCheck";
  direction: TabDirection;
};

type TabUiTypeBoxDark = {
  uiType: "boxDark";
  direction: "horizontal";
  isStretch?: boolean;
};

type TabUiTypeFile = {
  uiType: "file";
  direction: "horizontal";
};

export type TabUiTypeCase<T> =
  | TabUiTypeBox
  | TabUiTypeLined<T>
  | TabUiTypeRounded
  | TabUiTypeText
  | TabUiTypeRadioCheck
  | TabUiTypeBoxDark
  | TabUiTypeFile;

export type TabProps<T> = {
  selectedTab: T;
  onSelect: (value: T) => void;
  tabDataList: TabDataItem<T>[];
  className?: string;
  overflowStyle?: CSSProperties["overflow"];
} & TabUiTypeCase<T>;

export interface TabDataItem<T> {
  tabValue: T;
  title: React.ReactNode;
  iconInfo?: {
    Icon: SvgIconComponent;
    position: "left" | "right";
    color: string;
    onClick?: () => void;
  };
  disabled?: boolean;
}

export default function Tab<T>({
  direction,
  selectedTab,
  onSelect,
  tabDataList,
  className,
  overflowStyle = "auto",
  ...propsByUiType
}: TabProps<T>) {
  const getRadioIcon = useCallback(
    ({
      showsRadioIcon,
      disabled,
      selected,
    }: {
      showsRadioIcon: boolean;
      disabled?: boolean;
      selected?: boolean;
    }) => {
      if (!showsRadioIcon) return null;

      const iconWidth = 16;
      const iconHeight = 16;

      if (disabled)
        return <RadioDisabledIcon width={iconWidth} height={iconHeight} />;

      if (selected)
        return <RadioActiveCheckedIcon width={iconWidth} height={iconHeight} />;

      return <RadioActiveIcon width={iconWidth} height={iconHeight} />;
    },
    []
  );

  return (
    <Styled.container className={`${className ? className : ""} tab`}>
      <Styled.tabList
        uiType={propsByUiType.uiType}
        direction={direction}
        overflowStyle={overflowStyle}
        hasBorder={propsByUiType.uiType === "box" && propsByUiType.hasBorder}
        align={propsByUiType.uiType === "box" ? propsByUiType.align : undefined}
      >
        {tabDataList.map(({ tabValue, title, iconInfo, disabled }) => {
          const RadioIcon = getRadioIcon({
            showsRadioIcon: propsByUiType.uiType === "radioCheck",
            disabled,
            selected: tabValue === selectedTab,
          });

          const Icon = (() => {
            if (!iconInfo) return null;

            const { Icon, color, onClick, position } = iconInfo;

            return (
              <Icon
                width={12}
                height={12}
                color={color}
                onClick={(e) => {
                  e.stopPropagation();

                  onClick?.();
                }}
                className={`icon ${position}`}
              />
            );
          })();

          const isEmphasized = !!(
            propsByUiType.uiType === "lined" &&
            propsByUiType.emphasizedTabs?.includes(tabValue)
          );

          return (
            <Styled.tabItem
              key={`tab-${tabValue}`}
              id={`tab-${tabValue}`}
              onClick={() => onSelect(tabValue)}
              direction={direction}
              className={tabValue === selectedTab ? "selected" : "enable"}
              uiType={propsByUiType.uiType}
              type="button"
              disabled={disabled}
              isStretch={
                (propsByUiType.uiType === "box" ||
                  propsByUiType.uiType === "boxDark") &&
                propsByUiType.isStretch
              }
            >
              {RadioIcon && <div className="radio-icon">{RadioIcon}</div>}

              {iconInfo && iconInfo.position === "left" && Icon}

              {title}

              {iconInfo && iconInfo.position === "right" && Icon}

              {propsByUiType.uiType === "text" && <div className="divider" />}

              {isEmphasized && <div className="upper-dot" />}

              {propsByUiType.uiType === "lined" && (
                <span className="underline" />
              )}
            </Styled.tabItem>
          );
        })}
      </Styled.tabList>
    </Styled.container>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function CheckboxFilledDisabledIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_621_2654)">
          <path
            d="M0 5.71429C0 2.55804 2.55804 0 5.71429 0H34.2857C37.4375 0 40 2.55804 40 5.71429V34.2857C40 37.4375 37.4375 40 34.2857 40H5.71429C2.55804 40 0 37.4375 0 34.2857V5.71429ZM4.28571 5.71429V34.2857C4.28571 35.0714 4.925 35.7143 5.71429 35.7143H34.2857C35.0714 35.7143 35.7143 35.0714 35.7143 34.2857V5.71429C35.7143 4.925 35.0714 4.28571 34.2857 4.28571H5.71429C4.925 4.28571 4.28571 4.925 4.28571 5.71429Z"
            fill="#E0E0E0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.29191 34.2919V5.72049C4.29191 4.9312 4.9312 4.29191 5.72049 4.29191H34.2919C35.0776 4.29191 35.7205 4.9312 35.7205 5.72049V34.2919C35.7205 35.0776 35.0776 35.7205 34.2919 35.7205H5.72049C4.9312 35.7205 4.29191 35.0776 4.29191 34.2919Z"
            fill="#EEEEEE"
          />
        </g>
        <defs>
          <clipPath id="clip0_621_2654">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

import { css } from "styled-components";

import { ShipdaCurrentLanguage } from "@sellernote/_shared/src/i18n/i18nForShipda";

export type TypeLevel =
  | "Head1"
  | "Head2"
  | "Head3"
  | "Head4"
  | "Head5"
  | "Head6"
  | "SubHead1"
  | "SubHead2"
  | "SubHead3"
  | "SubHead4"
  | "Body1"
  | "Body2"
  | "Body3"
  | "Body4"
  | "Body5"
  | "Caption";

// EN 은 브링굿즈 작업하면서 주신다고 하셔서 보류. 이후 셋팅.
export type FontFace = "Default_KR" | "Point_ENG";

/**
 *
 * @param type 타입 종류, Heading Level
 * @param face 폰트 종류 (기본값 : "Default_KR")
 */
export function setFontStyle(type: TypeLevel, face?: FontFace) {
  if (!face)
    return css`
      ${setTypeLevel(type)}
      ${setFontFace("Default_KR")}
    `;

  return css`
    ${setTypeLevel(type, face)}
    ${setFontFace(face)}
  `;
}

const fontWeights = {
  bold: css`
    font-weight: 700;
  `,
  semiBold: css`
    font-weight: 600;
  `,
  medium: css`
    font-weight: 500;
  `,
  regular: css`
    font-weight: 400;
  `,
};

function setTypeLevel(type: TypeLevel, face?: FontFace) {
  switch (type) {
    case "Head1": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 40px;
          line-height: 48px;
          letter-spacing: 0.4px;
        `;
      else
        return css`
          font-size: 48px;
          line-height: 56px;
          letter-spacing: 0;
          ${fontWeights.bold}
        `;
    }
    case "Head2": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 36px;
          line-height: 46px;
          letter-spacing: 0.4px;
        `;
      else
        return css`
          font-size: 40px;
          line-height: 48px;
          letter-spacing: 0;
          ${fontWeights.bold}
        `;
    }
    case "Head3": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 32px;
          line-height: 42px;
          letter-spacing: 0.4px;
        `;
      else
        return css`
          font-size: 32px;
          line-height: 40px;
          letter-spacing: -0.2px;
          ${fontWeights.semiBold}
        `;
    }
    case "Head4": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 24px;
          line-height: 34px;
          letter-spacing: 0.4px;
        `;
      else
        return css`
          font-size: 28px;
          line-height: 36px;
          letter-spacing: -0.2px;
          ${fontWeights.medium}
        `;
    }
    case "Head5": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.4px;
        `;
      else
        return css`
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.2px;
          ${fontWeights.medium}
        `;
    }
    case "Head6": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.4px;
        `;
      else
        return css`
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.2px;
          ${fontWeights.medium}
        `;
    }

    case "SubHead1": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.1px;
        `;
      else
        return css`
          font-size: 20px;
          line-height: 26px;
          letter-spacing: -0.2px;
          ${fontWeights.medium}
        `;
    }
    case "SubHead2": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
        `;
      else
        return css`
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.25px;
          ${fontWeights.medium}
        `;
    }
    case "SubHead3": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.1px;
        `;
      else
        return css`
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.1px;
          ${fontWeights.medium}
        `;
    }
    case "SubHead4": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.1px;
        `;
      else
        return css`
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.1px;
          ${fontWeights.medium}
        `;
    }

    case "Body1": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 36px;
          line-height: 46px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 18px;
          line-height: 28px;
          letter-spacing: -0.2px;
          ${fontWeights.regular}
        `;
    }
    case "Body2": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 32px;
          line-height: 42px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.2px;
          ${fontWeights.regular}
        `;
    }
    case "Body3": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 24px;
          line-height: 30px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0;
          ${fontWeights.regular}
        `;
    }
    case "Body4": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0;
          ${fontWeights.regular}
        `;
    }
    case "Body5": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 10px;
          line-height: 14px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0;
          ${fontWeights.regular}
        `;
    }
    case "Caption": {
      if (
        face === "Point_ENG" &&
        ShipdaCurrentLanguage.currentLanguage === "en"
      )
        return css`
          font-size: 10px;
          line-height: 14px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0;
          ${fontWeights.regular}
        `;
    }
  }
}

export function setFontFace(type: FontFace) {
  switch (type) {
    case "Default_KR": {
      return css`
        font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
          Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
          "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji",
          "Segoe UI Symbol", sans-serif;
      `;
    }
    case "Point_ENG": {
      return css`
        font-family: "Mulish", sans-serif;
      `;
    }
  }
}

import React, { Dispatch, SetStateAction, useMemo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TODAY } from "@sellernote/_shared/src/utils/common/constants";
import {
  isSameDay,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";

import { SelectableTime, Time } from ".";
import Styled from "./index.styles";

export default function TimeSelect({
  date,
  setDateValue,
  endRef,
  time,
  setTime,
  minDate,
  isIncludedTime,
  isChangedMonth,
  selectableTime = { startHour: 9, endHour: 18 },
}: {
  date: Date | null | undefined;
  setDateValue: (val: string) => void;
  endRef?: any; // Date picker 라이브러리 props 로 가져오는 값이라 type 지정이 어려워 any로 지정.
  time: Time;
  setTime: Dispatch<SetStateAction<Time>>;
  minDate?: "today" | "tomorrow" | Date;
  isIncludedTime?: boolean;
  isChangedMonth: boolean;
  selectableTime?: SelectableTime;
}) {
  const selectedToday = date && isSameDay(TODAY, date);
  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();

  const HOUR_OPTION_LIST = useMemo(() => {
    const { startHour, endHour, disabledHour } = selectableTime;

    return Array.from({ length: endHour - startHour + 1 }, (v, i) => ({
      label: `${i + startHour}시`,
      value: i + startHour,
    })).filter(({ value }) => value !== disabledHour);
  }, [selectableTime]);

  const MINUTE_OPTION_LIST = useMemo(
    () =>
      new Array(6).fill({}).map((val, i) => ({
        label: `${i * 10}분`,
        value: i * 10,
      })),
    []
  );

  const ZERO_MINUTE_OPTION_LIST = [{ label: "0분", value: 0 }];

  const EXCLUDING_CURRENT_PREVIOUS_HOUR_OPTION_LIST = useMemo(() => {
    if (currentMinute >= 50)
      return HOUR_OPTION_LIST.filter(({ value }) => value > currentHour);

    return HOUR_OPTION_LIST.filter(({ value }) => value >= currentHour);
  }, [HOUR_OPTION_LIST, currentHour, currentMinute]);

  const EXCLUDING_CURRENT_PREVIOUS_MINUTE_OPTION_LIST = useMemo(() => {
    if (currentMinute >= 50) return MINUTE_OPTION_LIST;

    return MINUTE_OPTION_LIST.filter(({ value }) => value > currentMinute);
  }, [MINUTE_OPTION_LIST, currentMinute]);

  function handleComplete() {
    if (!date) return;

    const includedTimeDate = date;

    includedTimeDate.setHours(time.hour ?? 0);
    includedTimeDate.setMinutes(time.minute ?? 0);

    setDateValue(
      `${toFormattedDate(
        includedTimeDate as Date,
        "YYYY-MM-DDTHH:mm:ss.SSS",
        true
      )}Z`
    );

    if (!endRef.current) return;

    endRef.current.setOpen(false);
  }

  return (
    <Styled.selectTimeContainer>
      <div className="title-select-container">
        <span className="title-time">시간</span>

        <InputSelect
          label="시"
          uiType="filled"
          optionList={
            minDate === "today" && selectedToday
              ? EXCLUDING_CURRENT_PREVIOUS_HOUR_OPTION_LIST
              : HOUR_OPTION_LIST
          }
          selectedOption={HOUR_OPTION_LIST.find(
            ({ value }) => value === time.hour
          )}
          handleSelect={(v) =>
            setTime((prev) => ({
              ...prev,
              hour: v.value,
              minute: v.value === selectableTime?.endHour ? 0 : prev.minute,
            }))
          }
          disabled={isChangedMonth}
        />

        <InputSelect
          label="분"
          uiType="filled"
          optionList={
            time.hour === selectableTime?.endHour
              ? ZERO_MINUTE_OPTION_LIST
              : minDate === "today" &&
                selectedToday &&
                currentHour === time.hour
              ? EXCLUDING_CURRENT_PREVIOUS_MINUTE_OPTION_LIST
              : MINUTE_OPTION_LIST
          }
          selectedOption={MINUTE_OPTION_LIST.find(
            ({ value }) => value === time.minute
          )}
          handleSelect={(v) =>
            setTime((prev) => ({ ...prev, minute: v.value }))
          }
          disabled={isChangedMonth}
        />
      </div>

      <Button
        label="완료"
        theme="primary"
        size="block"
        handleClick={handleComplete}
        disabled={
          isIncludedTime
            ? !(date && Object.values(time).every((v) => typeof v === "number"))
            : !date
        }
      />
    </Styled.selectTimeContainer>
  );
}

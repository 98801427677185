import SvgIconWrapper from "./SvgIconWrapper";

export default function MagnifyingGlassIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7364)">
          <path
            d="M37.1773 33.1976L28.7609 24.7812C30.6749 21.9399 31.6191 18.3898 31.1136 14.607C30.2516 8.17271 24.9781 2.93654 18.5375 2.12112C8.96165 0.90943 0.909464 8.96161 2.12123 18.5375C2.93685 24.9809 8.17373 30.2586 14.6087 31.1164C18.3915 31.6218 21.9423 30.6779 24.7829 28.7637L33.1994 37.1801C34.2976 38.2784 36.0786 38.2784 37.1769 37.1801C38.2742 36.0804 38.2742 34.2945 37.1773 33.1976ZM7.56173 16.625C7.56173 11.6623 11.5991 7.62497 16.5617 7.62497C21.5244 7.62497 25.5617 11.6623 25.5617 16.625C25.5617 21.5876 21.5244 25.625 16.5617 25.625C11.5991 25.625 7.56173 21.589 7.56173 16.625Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7364">
            <rect
              width="36"
              height="36"
              fill="white"
              transform="translate(2 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

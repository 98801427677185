import React from "react";

import { applyCustomProps, applyUniqueId } from "./utils";

/**
 * SVG아이콘을 React프로젝트에서 (SVG코드 내부의 id충돌 등)문제없이 사용할 수 있도록 변환해주는 wrapper
 */
export default function SvgIconWrapper({
  children,
  ...customProps
}: {
  children: React.ReactElement<SVGElement>;
} & React.SVGAttributes<SVGElement>) {
  return applyUniqueId(applyCustomProps(children, customProps));
}

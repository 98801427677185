import SvgIconWrapper from "./SvgIconWrapper";

export default function ExpandArrowLessCircleIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M2 20C2 24.7739 3.89642 29.3523 7.27208 32.7279C10.6477 36.1036 15.2261 38 20 38C24.7739 38 29.3523 36.1036 32.7279 32.7279C36.1036 29.3523 38 24.7739 38 20C38 15.2261 36.1036 10.6477 32.7279 7.27208C29.3523 3.89642 24.7739 2 20 2C15.2261 2 10.6477 3.89642 7.27208 7.27208C3.89642 10.6477 2 15.2261 2 20ZM18.9453 28.5078C18.2844 29.1688 17.2156 29.1688 16.5617 28.5078C15.9078 27.8469 15.9008 26.7781 16.5617 26.1242L22.6789 20.007L16.5617 13.8898C15.9008 13.2289 15.9008 12.1602 16.5617 11.5062C17.2227 10.8523 18.2914 10.8453 18.9453 11.5062L26.2578 18.8047C26.9188 19.4656 26.9188 20.5344 26.2578 21.1883L18.9453 28.5078Z"
          fill="currentColor"
        />
      </svg>
    </SvgIconWrapper>
  );
}

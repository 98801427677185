import SvgIconWrapper from "./SvgIconWrapper";

export default function ExclamationTriangleIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.58333 35.0417C4 35.0417 3.5625 34.7986 3.27083 34.3125C2.97917 33.8264 2.97222 33.3472 3.25 32.875L18.75 6.20833C19.0556 5.73611 19.4722 5.5 20 5.5C20.5278 5.5 20.9444 5.73611 21.25 6.20833L36.75 32.875C37.0278 33.3472 37.0208 33.8264 36.7292 34.3125C36.4375 34.7986 36.0139 35.0417 35.4583 35.0417H4.58333ZM20.125 16.625C19.7917 16.625 19.5069 16.7431 19.2708 16.9792C19.0347 17.2153 18.9167 17.4861 18.9167 17.7917V24.5417C18.9167 24.9028 19.0417 25.2014 19.2917 25.4375C19.5417 25.6736 19.8333 25.7917 20.1667 25.7917C20.5 25.7917 20.7847 25.6736 21.0208 25.4375C21.2569 25.2014 21.375 24.9028 21.375 24.5417V17.7917C21.375 17.4861 21.25 17.2153 21 16.9792C20.75 16.7431 20.4583 16.625 20.125 16.625ZM20.1667 30.3333C20.5278 30.3333 20.8333 30.2014 21.0833 29.9375C21.3333 29.6736 21.4583 29.3611 21.4583 29C21.4583 28.6667 21.3264 28.3681 21.0625 28.1042C20.7986 27.8403 20.4861 27.7083 20.125 27.7083C19.7639 27.7083 19.4583 27.8403 19.2083 28.1042C18.9583 28.3681 18.8333 28.6806 18.8333 29.0417C18.8333 29.375 18.9653 29.6736 19.2292 29.9375C19.4931 30.2014 19.8056 30.3333 20.1667 30.3333Z"
          fill="currentColor"
        />
      </svg>
    </SvgIconWrapper>
  );
}

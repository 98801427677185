import SvgIconWrapper from "./SvgIconWrapper";

export default function InfoFilledIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7591)">
          <path
            d="M20 2C10.0578 2 2 10.0578 2 20C2 29.9422 10.0578 38 20 38C29.9422 38 38 29.9422 38 20C38 10.0578 29.9422 2 20 2ZM20 11C21.2424 11 22.25 12.0076 22.25 13.25C22.25 14.4924 21.2424 15.5 20 15.5C18.7576 15.5 17.75 14.4945 17.75 13.25C17.75 12.0055 18.7555 11 20 11ZM22.8125 29H17.1875C16.2594 29 15.5 28.2477 15.5 27.3125C15.5 26.3773 16.2559 25.625 17.1875 25.625H18.3125V21.125H17.75C16.8184 21.125 16.0625 20.3691 16.0625 19.4375C16.0625 18.5059 16.8219 17.75 17.75 17.75H20C20.9316 17.75 21.6875 18.5059 21.6875 19.4375V25.625H22.8125C23.7441 25.625 24.5 26.3809 24.5 27.3125C24.5 28.2441 23.7477 29 22.8125 29Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7591">
            <rect
              width="36"
              height="36"
              fill="white"
              transform="translate(2 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function FourChevronLeftIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.5 33.5C23.9243 33.5 23.3483 33.2803 22.9095 32.8408L11.6595 21.5908C10.7806 20.7119 10.7806 19.2881 11.6595 18.4092L22.9095 7.15918C23.7884 6.28027 25.2123 6.28027 26.0912 7.15918C26.9701 8.03809 26.9701 9.46191 26.0912 10.3408L16.4316 20L26.0926 29.6609C26.9715 30.5398 26.9715 31.9637 26.0926 32.8426C25.6531 33.282 25.0766 33.5 24.5 33.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIconWrapper>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function CheckboxOutlinedDisabledIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2172_15769)">
          <path
            d="M0 5.71429C0 2.55804 2.55804 0 5.71429 0H34.2857C37.4375 0 40 2.55804 40 5.71429V34.2857C40 37.4375 37.4375 40 34.2857 40H5.71429C2.55804 40 0 37.4375 0 34.2857V5.71429ZM4.28571 5.71429V34.2857C4.28571 35.0714 4.925 35.7143 5.71429 35.7143H34.2857C35.0714 35.7143 35.7143 35.0714 35.7143 34.2857V5.71429C35.7143 4.925 35.0714 4.28571 34.2857 4.28571H5.71429C4.925 4.28571 4.28571 4.925 4.28571 5.71429Z"
            fill="#E0E0E0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.29199 34.292V5.72056C4.29199 4.93128 4.93128 4.29199 5.72056 4.29199H34.292C35.0777 4.29199 35.7206 4.93128 35.7206 5.72056V34.292C35.7206 35.0777 35.0777 35.7206 34.292 35.7206H5.72056C4.93128 35.7206 4.29199 35.0777 4.29199 34.292Z"
            fill="#EEEEEE"
          />
        </g>
        <defs>
          <clipPath id="clip0_2172_15769">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function TruckAndTrainIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 235">
          <g id="Mask group">
            <mask
              id="mask0_5419_1651"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="27"
              height="18"
            >
              <path
                id="Rectangle 219"
                d="M12.3945 10.6613H1.00006C-0.656793 10.6613 1.00002 -0.995597 1.00002 0.661257H27L26 14.1613L18.8907 17.9972L14.8907 11.9972C14.3343 11.1626 13.3976 10.6613 12.3945 10.6613Z"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_5419_1651)">
              <g id="Layer_x0020_1">
                <path
                  id="Vector"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22.782 13.025H22.6221C22.5114 11.8071 21.478 10.8598 20.2355 10.8598C18.9929 10.8598 17.9595 11.8071 17.8488 13.025H13.9613C13.8383 11.8071 12.8172 10.8598 11.5747 10.8598C10.3198 10.8598 9.29875 11.8071 9.17573 13.025H8.92969C8.80666 11.8071 7.78558 10.8598 6.53075 10.8598C5.28822 10.8598 4.26713 11.8071 4.14411 13.025C3.50439 13.0004 3 12.4714 3 11.8194V9.80185C3 9.66652 3.11072 9.5558 3.24605 9.5558H17.3813V4.32734C17.3813 4.19202 17.4921 4.0813 17.6274 4.0813H19.0667V7.32909C19.0667 8.07953 19.6696 8.68234 20.42 8.68234H24V11.8194C23.9876 12.4837 23.4463 13.025 22.782 13.025ZM19.5588 4.0813H21.0105C22.659 4.0813 24 5.42224 24 7.07075V8.19025H20.42C19.9402 8.19025 19.5588 7.80888 19.5588 7.32909V4.0813Z"
                  fill="currentColor"
                />
                <path
                  id="Vector_2"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.575 15.252C10.4678 15.252 9.58203 14.354 9.58203 13.2591C9.58203 12.1642 10.4678 11.2661 11.575 11.2661C12.6699 11.2661 13.5557 12.1642 13.5557 13.2591C13.5557 14.354 12.6699 15.252 11.575 15.252ZM11.575 14.2433C10.2833 14.2433 10.2833 12.2749 11.575 12.2749C12.8544 12.2749 12.8544 14.2433 11.575 14.2433Z"
                  fill="currentColor"
                />
                <path
                  id="Vector_3"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.53047 15.252C5.43557 15.252 4.5498 14.354 4.5498 13.2591C4.5498 12.1642 5.43557 11.2661 6.53047 11.2661C7.63767 11.2661 8.52343 12.1642 8.52343 13.2591C8.52343 14.354 7.63767 15.252 6.53047 15.252ZM6.53047 14.2433C5.25103 14.2433 5.25103 12.2749 6.53047 12.2749C7.82221 12.2749 7.82221 14.2433 6.53047 14.2433Z"
                  fill="currentColor"
                />
                <path
                  id="Vector_4"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.2352 15.252C19.1403 15.252 18.2422 14.354 18.2422 13.2591C18.2422 12.1642 19.1403 11.2661 20.2352 11.2661C21.3301 11.2661 22.2281 12.1642 22.2281 13.2591C22.2281 14.354 21.3301 15.252 20.2352 15.252ZM20.2352 14.2433C18.9434 14.2433 18.9434 12.2749 20.2352 12.2749C21.5269 12.2749 21.5269 14.2433 20.2352 14.2433Z"
                  fill="currentColor"
                />
                <path
                  id="Vector_5"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.6467 10.4072H3.25334C3.114 10.4072 3 10.2965 3 10.1611V1.90718C3 1.77185 3.114 1.66113 3.25334 1.66113H16.6467C16.786 1.66113 16.9 1.77185 16.9 1.90718V10.1611C16.9 10.2965 16.786 10.4072 16.6467 10.4072Z"
                  fill="currentColor"
                />
                <path
                  id="Vector_6"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.8224 10.2569C14.5764 10.2569 14.3795 10.0478 14.3795 9.80174V8.4485C14.3795 7.85799 15.2776 7.85799 15.2776 8.4485V9.80174C15.2776 10.0478 15.0685 10.2569 14.8224 10.2569ZM5.16514 10.2569C4.9068 10.2569 4.70996 10.0478 4.70996 9.80174V8.4485C4.70996 7.85799 5.60803 7.85799 5.60803 8.4485V9.80174C5.60803 10.0478 5.41119 10.2569 5.16514 10.2569Z"
                  fill="currentColor"
                />
              </g>
            </g>
          </g>
          <g id="Page-1">
            <g id="020---Train">
              <path
                id="Shape"
                d="M11.2002 13.9502V15.2722C11.2002 15.5424 11.3673 15.7612 11.5737 15.7612H15.7406L14.5002 13.4612H11.5732C11.3668 13.4612 11.2002 13.6799 11.2002 13.9502Z"
                fill="currentColor"
              />
              <path
                id="Subtract"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.148 16.2515L16.6096 16.9485C16.6096 17.5553 16.6207 18.0861 16.6303 18.5494C16.6348 18.768 16.6391 18.9716 16.6417 19.1611H-3V19.9611H16.6367C16.5886 21.1754 16.2884 21.5265 15 21.5265H14.3709C14.2822 22.4545 13.5062 23.1626 12.5774 23.1626C11.6486 23.1626 10.8722 22.4545 10.7839 21.5265H9.11226C9.02394 22.4545 8.24763 23.1626 7.3188 23.1626C6.38997 23.1626 5.61366 22.4545 5.52534 21.5265H-8V12.3186C-7.99889 11.9562 -7.7061 11.6623 -7.34503 11.6611H12.2081C12.9755 11.6611 13.6921 12.0463 14.1177 12.687L14.311 12.9765H11.7758C11.2336 12.978 10.7946 13.4187 10.7928 13.9629V15.2651C10.7946 15.8089 11.2336 16.2496 11.7758 16.2515H16.148ZM2.47122 16.2515C3.01338 16.2496 3.45237 15.8089 3.45386 15.2651V13.9629C3.45237 13.4187 3.01338 12.978 2.47122 12.9765H0.178278C-0.363879 12.978 -0.802875 13.4187 -0.804359 13.9629V15.2651C-0.802875 15.8089 -0.363879 16.2496 0.178278 16.2515H2.47122ZM4.55153 15.2651C4.55302 15.8089 4.99238 16.2496 5.53417 16.2515H7.82748C8.36927 16.2496 8.80864 15.8089 8.81012 15.2651V13.9629C8.80864 13.4187 8.36927 12.978 7.82748 12.9765H5.53417C4.99238 12.978 4.55302 13.4187 4.55153 13.9629V15.2651Z"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIconWrapper>
  );
}

import Styled from "./index.styles";

const TableBody = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return <Styled.tableBody className={className}>{children}</Styled.tableBody>;
};

export default TableBody;

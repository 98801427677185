import SvgIconWrapper from "./SvgIconWrapper";

export default function FourChevronUpIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_914_7415)">
          <path
            d="M31.25 26.75C30.6743 26.75 30.0983 26.5303 29.6595 26.0908L20 16.4352L10.3391 26.0961C9.46016 26.975 8.03633 26.975 7.15742 26.0961C6.27852 25.2172 6.27852 23.7934 7.15742 22.9145L18.4074 11.6645C19.2863 10.7855 20.7102 10.7855 21.5891 11.6645L32.8391 22.9145C33.718 23.7934 33.718 25.2172 32.8391 26.0961C32.4031 26.532 31.8266 26.75 31.25 26.75Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_914_7415">
            <rect
              width="27"
              height="36"
              fill="white"
              transform="translate(6.5 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function CheckboxFilledActiveIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_621_2651)">
          <path
            d="M0 5.96429C0 2.80804 2.55804 0.25 5.71429 0.25H34.2857C37.4375 0.25 40 2.80804 40 5.96429V34.5357C40 37.6875 37.4375 40.25 34.2857 40.25H5.71429C2.55804 40.25 0 37.6875 0 34.5357V5.96429ZM4.28571 5.96429V34.5357C4.28571 35.3214 4.925 35.9643 5.71429 35.9643H34.2857C35.0714 35.9643 35.7143 35.3214 35.7143 34.5357V5.96429C35.7143 5.175 35.0714 4.53571 34.2857 4.53571H5.71429C4.925 4.53571 4.28571 5.175 4.28571 5.96429Z"
            fill="#BDBDBD"
          />
        </g>
        <defs>
          <clipPath id="clip0_621_2651">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

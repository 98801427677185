import SvgIconWrapper from "./SvgIconWrapper";

/**
 * 운송관리 인포그래픽 영역에서 사용할 DotIcon 입니다.
 *
 * https://www.figma.com/file/iHlih9UuVqh5HKr6iKZMVc/branch/U88PUWR9edHRvqK729gpUP/MyPage-Renewal?type=design&node-id=13125-41020&mode=design&t=m0QHElGsaHwSGvEK-0
 *
 * 피그마 아이콘 에셋이 아닌 인포그래픽에서만 사용할걸로 예상되어 Disabled 와 같이 고정 컬러값을 사용합니다.
 */
export default function DotOutlinedIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="5"
          cy="5"
          r="4.5"
          fill="#EEEEEE"
          stroke="#BDBDBD"
          strokeDasharray="2 2"
        />
      </svg>
    </SvgIconWrapper>
  );
}

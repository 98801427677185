import SvgIconWrapper from "./SvgIconWrapper";

export default function FourChevronDownIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_918_7366)">
          <g clipPath="url(#clip1_918_7366)">
            <path
              d="M20 29C19.4243 29 18.8483 28.7803 18.4095 28.3408L7.15953 17.0908C6.28062 16.2119 6.28062 14.7881 7.15953 13.9092C8.03844 13.0303 9.46226 13.0303 10.3412 13.9092L20 23.5719L29.6609 13.9109C30.5398 13.032 31.9637 13.032 32.8426 13.9109C33.7215 14.7898 33.7215 16.2137 32.8426 17.0926L21.5926 28.3426C21.1531 28.782 20.5766 29 20 29Z"
              fill="currentColor"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_918_7366">
            <rect width="40" height="40" fill="white" />
          </clipPath>
          <clipPath id="clip1_918_7366">
            <rect
              width="27"
              height="36"
              fill="white"
              transform="translate(6.5 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}

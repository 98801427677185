import SvgIconWrapper from "./SvgIconWrapper";

export default function BargeIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="17"
        viewBox="0 0 24 17"
        fill="none"
      >
        <path
          d="M23.3982 5.83541C23.3982 5.55825 23.1757 5.33203 22.8985 5.33203H15.8942C15.617 5.33203 15.3945 5.55451 15.3945 5.83541V11.1119C15.3945 11.389 15.1683 11.6152 14.8947 11.6152H1.10159C0.722182 11.6152 0.481355 12.0201 0.660195 12.3557L1.22556 13.7494H23.3982V5.83541Z"
          fill="currentColor"
          fillOpacity="0.8"
        />
        <path
          d="M1.44965 14.2981L1.80536 15.1736C2.15485 16.0338 2.99079 16.5963 3.91918 16.5963H21.1185C22.3785 16.5963 23.4 15.5753 23.4 14.3156V14.2981C18.2009 14.2981 6.51776 14.2981 1.44965 14.2981Z"
          fill="currentColor"
          fillOpacity="0.8"
        />
        <path
          d="M6.88292 3.93756L7.80249 3.39962C7.88007 3.35438 7.97599 3.35438 8.05347 3.39962L8.97305 3.93756C9.1387 4.03447 9.34693 3.91494 9.34693 3.7231V0.948975H6.50903V3.7231C6.50904 3.91494 6.71735 4.03446 6.88292 3.93756Z"
          fill="currentColor"
          fillOpacity="0.8"
        />
        <path
          d="M11.7391 8.04297H9.27386C9.24607 8.04297 9.22345 8.06559 9.22345 8.09338V9.31407C9.22345 9.34186 9.24607 9.36466 9.27386 9.36466H11.7391C11.7671 9.36466 11.7897 9.34186 11.7897 9.31407V8.09338C11.7897 8.06559 11.7671 8.04297 11.7391 8.04297Z"
          fill="currentColor"
          fillOpacity="0.8"
        />
        <path
          d="M13.5085 11.068V2.35283C13.5085 1.57941 12.8774 0.948486 12.1003 0.948486H9.89334V3.72457C9.89334 4.32895 9.23783 4.72172 8.69669 4.41036L7.92701 3.96166L7.16089 4.41036C6.59713 4.72546 5.96068 4.31524 5.96068 3.72457V0.948486H3.75736C2.98028 0.948486 2.34918 1.57941 2.34918 2.35657V11.068H13.5048H13.5085ZM5.5403 9.68911H3.79289C3.64184 9.68911 3.51929 9.56656 3.51929 9.41551C3.51929 9.26446 3.64184 9.14191 3.79289 9.14191H5.5403C5.69135 9.14191 5.8139 9.26446 5.8139 9.41551C5.8139 9.56656 5.69135 9.68911 5.5403 9.68911ZM7.00752 8.33999H3.79289C3.64184 8.33999 3.51929 8.21744 3.51929 8.06639C3.51929 7.91516 3.64184 7.79278 3.79289 7.79278H7.00752C7.15857 7.79278 7.28112 7.91516 7.28112 8.06639C7.28112 8.21744 7.15857 8.33999 7.00752 8.33999ZM12.3369 9.31398C12.3369 9.64351 12.0686 9.91177 11.7391 9.91177H9.27382C8.94429 9.91177 8.67621 9.64351 8.67621 9.31398V8.09328C8.67621 7.76375 8.94429 7.49567 9.27382 7.49567H11.7391C12.0686 7.49567 12.3369 7.76375 12.3369 8.09328V9.31398Z"
          fill="currentColor"
          fillOpacity="0.8"
        />
      </svg>
    </SvgIconWrapper>
  );
}

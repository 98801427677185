import React, { useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { enUS, ko } from "date-fns/locale";

import "react-datepicker/dist/react-datepicker.css";

import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { ShipdaCurrentLanguage } from "@sellernote/_shared/src/i18n/i18nForShipda";
import {
  TODAY,
  TOMORROW,
} from "@sellernote/_shared/src/utils/common/constants";
import {
  getAppTodayMidnight,
  getAppTomorrowMidnight,
  getHolidayList,
  isSameDay,
  isWeekday,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";

import { InputTextBorderType } from "../input/InputText";
import CustomCalender from "./CustomCalender";
import CustomInput from "./CustomInput";
import TimeSelect from "./TimeSelect";
import Styled from "./index.styles";

export interface Time {
  hour: number | null;
  minute: number | null;
}
export interface SelectTime {
  hour: InputSelectOption<string>;
  minute: InputSelectOption<string>;
}

export interface SelectableTime {
  startHour: number;
  endHour: number;
  disabledHour?: number;
}

// FIXME: Calendar는 요구사항 정리해서 무조건 새로 만들어야 함
export default function Calendar({
  inputLabel,
  dateValue,
  setDateValue,
  isIncludedTime,
  disabled,
  minDate,
  maxDate,
  displayHolidays,
  selectableTime,
  inline,
  inputBorderType,
  placeholder,
  calendarRef,
  openToDate,
  className,
}: {
  inputLabel?: string;
  dateValue: string;
  setDateValue: (val: string) => void;
  isIncludedTime?: boolean;
  disabled?: boolean;
  minDate?: "today" | "tomorrow" | Date;
  maxDate?: "today" | "tomorrow" | Date;
  displayHolidays: "enabled" | "disabled" | "modal";
  selectableTime?: SelectableTime;
  inline?: boolean;
  inputBorderType?: InputTextBorderType;
  placeholder?: string;
  /**
   * 이 컴포넌트를 ref로 사용하고 싶을 때 사용
   * (ex. 외부 클릭시 캘린더 닫기)
   */
  calendarRef?: React.RefObject<HTMLDivElement>;
  /** 캘린더 오픈시 사용하는 기준 날짜  */
  openToDate?: string | Date;

  className?: string;
}) {
  const endRef = useRef<any>();

  const [date, setDate] = useState<Date | null | undefined>(null);

  const [time, setTime] = useState<Time>({
    hour: null,
    minute: null,
  });

  const [visibleHolidayGuide, setVisibleHolidayGuide] = useState(false);

  // 월을 변경하면 날짜 선택을 강제하기 위해 사용
  const [isChangedMonth, setIsChangedMonth] = useState(false);

  const holidayDateList = getHolidayList().map((date) => new Date(date));

  const isHoliday = (date: Date) => {
    return holidayDateList.some((holidayDate) => isSameDay(holidayDate, date));
  };

  const isWeekdayAndHoliday = (date: Date) =>
    isWeekday(date) || isHoliday(date);

  function getDayName(date: Date) {
    return date.toLocaleDateString("ko-KR", { weekday: "long" }).substr(0, 1);
  }

  function createDate(date: Date) {
    return new Date(
      new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    );
  }

  const getDayClassName = (date: Date) => {
    const dayName = getDayName(createDate(date));

    if (dayName === "일") return "sunday";

    if (isHoliday(date)) return "holiday";

    return "";
  };

  const minDateOption = useMemo(() => {
    if (!minDate) return;

    if (minDate === "today") return getAppTodayMidnight();

    if (minDate === "tomorrow") return getAppTomorrowMidnight();

    return minDate;
  }, [minDate]);

  const maxDateOption = (() => {
    if (!maxDate) return;

    if (maxDate === "today") return getAppTodayMidnight();

    if (maxDate === "tomorrow") return getAppTomorrowMidnight();

    return maxDate;
  })();

  const handleDateChange = (date: Date | [Date | null, Date | null] | null) => {
    if (displayHolidays === "modal" && isWeekdayAndHoliday(date as Date)) {
      setVisibleHolidayGuide(true);
    }

    setDate(date as Date);
    setIsChangedMonth(false);
  };

  function CustomContainerAndTimeSelect(
    className: string,
    children: React.ReactNode[]
  ) {
    return (
      <Styled.container>
        <div className={className}>
          <div style={{ position: "relative" }}>{children}</div>
        </div>

        {isIncludedTime && (
          <TimeSelect
            {...{
              date,
              setDateValue,
              endRef,
              time,
              setTime,
              minDate,
              isIncludedTime,
              isChangedMonth,
              selectableTime,
            }}
          />
        )}
      </Styled.container>
    );
  }

  useEffect(() => {
    if (isIncludedTime && !dateValue) {
      const defaultDate = (() => {
        if (minDate === "today") {
          return TODAY;
        }

        if (minDate === "tomorrow") {
          return TOMORROW;
        }

        return TODAY;
      })();

      defaultDate.setHours(0);
      defaultDate.setMinutes(0);

      setDate(defaultDate);
    }
  }, [isIncludedTime]);

  useEffect(() => {
    if (!dateValue) {
      setDateValue("");
      setDate(null);
      setTime({
        hour: null,
        minute: null,
      });
    }

    if (dateValue) {
      const savedDate = new Date(dateValue);

      setDate(new Date(savedDate));

      setTime({
        hour: savedDate.getHours(),
        minute: savedDate.getMinutes(),
      });
    }
  }, [dateValue]);

  useEffect(() => {
    if (disabled) {
      setDate(null);
      setTime({
        hour: null,
        minute: null,
      });
      setDateValue("");
      return;
    }

    if (!date) return;

    if (!isIncludedTime) {
      setDateValue(
        `${toFormattedDate(date as Date, "YYYY-MM-DD")}T00:00:00.000Z`
      );
    }
    // setDateValue를 의존성에 넣으면 무한 루프가 발생한다.
  }, [date, isIncludedTime, disabled]);

  // 날짜 바꾸면 시간 초기화
  useEffect(() => {
    if (date) {
      if (isSameDay(new Date(dateValue), date)) return;

      const selectedDate = date;

      selectedDate.setHours(0);
      selectedDate.setMinutes(0);

      setDate(selectedDate);
      setTime({
        hour: null,
        minute: null,
      });
    }
  }, [date]);

  return (
    <>
      <Styled.calendarWrapper
        className={`${className ? className : ""} input-text-with-calendar`}
        ref={calendarRef}
      >
        <DatePicker
          ref={endRef}
          selected={date}
          onChange={handleDateChange}
          locale={(() => {
            if (ShipdaCurrentLanguage.currentLanguage === "en") {
              return enUS;
            }

            return ko;
          })()}
          calendarContainer={({
            className,
            children,
          }: {
            className: string;
            children: React.ReactNode[];
          }) => CustomContainerAndTimeSelect(className, children)}
          dateFormat={"yyyy-MM-dd"}
          minDate={minDateOption} // 과거 날짜 선택 disable Date 객체
          maxDate={maxDateOption}
          dayClassName={getDayClassName}
          // 포함할 날짜
          filterDate={(date) =>
            displayHolidays === "disabled" ? !isWeekdayAndHoliday(date) : true
          }
          openToDate={(() => {
            if (!openToDate) return;

            if (typeof openToDate === "string") {
              return new Date(openToDate);
            }

            return openToDate;
          })()}
          placeholderText={placeholder ?? ""}
          customInput={
            <CustomInput
              {...{
                dateValue,
                inputLabel,
                isIncludedTime,
                setDateValue,
                date,
                setDate,
                time,
                setTime,
                inputBorderType,
              }}
            />
          }
          renderCustomHeader={({
            date,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            decreaseMonth,
            increaseMonth,
          }) => (
            <CustomCalender
              data={{
                date,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
                decreaseMonth,
                increaseMonth,
              }}
              {...{ visibleHolidayGuide, setVisibleHolidayGuide }}
              setIsChangedMonth={setIsChangedMonth}
            />
          )}
          shouldCloseOnSelect={!isIncludedTime}
          disabled={disabled}
          inline={inline}
        />
      </Styled.calendarWrapper>
    </>
  );
}

import SvgIconWrapper from "./SvgIconWrapper";

export default function ExpandArrowMoreCircleIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7653)">
          <path
            d="M20 2C10.0578 2 2 10.0578 2 20C2 29.9422 10.0578 38 20 38C29.9422 38 38 29.9422 38 20C38 10.0578 29.9422 2 20 2ZM29.4641 19.3391L21.5891 27.2141C21.1531 27.657 20.5766 27.875 20 27.875C19.4234 27.875 18.8483 27.6553 18.4095 27.2158L10.5345 19.3408C9.65562 18.4619 9.65562 17.0381 10.5345 16.1592C11.4134 15.2803 12.8373 15.2803 13.7162 16.1592L20 22.4469L26.2845 16.1623C27.1634 15.2834 28.5873 15.2834 29.4662 16.1623C30.3451 17.0413 30.343 18.4602 29.4641 19.3391Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7653">
            <rect
              width="36"
              height="36"
              fill="white"
              transform="translate(2 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
